@tailwind base;
@tailwind components;

body {
    margin: 0;
    background: linear-gradient(135deg, rgba(71, 156, 224, .95), #9ECFA2, #E7E233);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: linear-gradient(
            154.06deg,
            rgba(71, 156, 224, 0.855) 21.52%,
            rgba(158, 207, 162, 0.9) 51.71%,
            rgba(231, 226, 51, 0.9) 83.78%
        )
        repeat scroll 0% 0% transparent;
    background-attachment: fixed;
}

@tailwind utilities;

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
